const { SITE_URL } = window['RUN_CONFIG'];

export const UKRAINIAN_LANGUAGE = 'ua';
export const RUSSIAN_LANGUAGE = 'ru';

// Video api configuration
export const SERIES_CATEGORY_NUMBER = 4;
export const CARTOONS_CATEGORY_NUMBER = 6;
export const SHOWS_CATEGORY_NUMBER = 9;
export const MOVIES_CATEGORY_NUMBER = 16;

// Channels api configuration
export const MOVIES_GENRE_ID = 'Movies';

// Fetch statuses
export const STATUS_LOADING = 'loading';
export const STATUS_SUCCESS = 'succeeded';
export const STATUS_FAILED = 'failed';

export const MIN_SEARCH_STRING_LENGTH = 3;
export const MAX_SEARCH_STRING_LENGTH = 30;

// Media queries
export const MOBILE_MEDIA_QUERY = 360;
export const TABLET_MEDIA_QUERY = 720;
export const DESKTOP_SD_VIEWPORT_SIZE = 1280;
export const DESKTOP_HD_MEDIA_QUERY = 1440;

// Payments
export const VISA_PAYMENT_CARD_TYPE = '4';
export const MASTERCARD_PAYMENT_CARD_TYPE = '5';
export const PAYMENT_VIDEO_TYPE = 'billing';
export const PAYMENT_SUBSCRIPTION_TYPE = 'billing-regular';
export const PAYMENT_SUCCESS_ABSOLUTE_URL_PATH = `${SITE_URL}/payment/success`;

// Auth
export const UNAUTHENTICATED_ERROR = 'Unauthenticated';
export const REFRESH_TOKEN_IS_INVALID = 'The refresh token is invalid.';
export const ACCOUNT_NOT_CONFIRMED_ERROR = 'The account is not confirmed.'; // error text from API
export const TOO_MANY_REQUESTS_ERROR = 'maxNumberDevices'; // error text from API
export const AUTHORIZATION_FAILED = 'Authorization failed.'; // error text from API

// Parent Control
export const PIN_INVALID_ERROR = 'Failed to unset parental control.';
export const PIN_VALIDATION_ERROR = 'Failed to validate parental control PIN.';
export const IS_CHECK_PARENT_CONTROL = 'isCheckParentControl';

// Subscriptions
export const SUBSCRIPTION_IS_BOUGHT_ERROR = 'The subscription is bought.';
export const PROMO_CODE_IS_NOT_VALID = 'Promo-code is not valid.';
export const GROUP_NAME_EXTERNAL = 'Внешняя';
export const SUBSCRIPTION_IS_BOUGHT_WEB = 'inner';
export const SUBSCRIPTION_IS_BOUGHT_ANDROID = 'google';
export const SUBSCRIPTION_IS_BOUGHT_IOS = 'apple';

// env
export const AmplitudeKey = process.env.REACT_APP_AMPLITUDE_KEY || null;
export const CrazyEgg = process.env.REACT_APP_IS_CRAZYEGG || null;

//logrocket
export const CHECK_PASSPHRASE_LOG_ROCKET = '1111';
export const LogRocketKey = process.env.REACT_APP_LOG_ROCKET_KEY || null;
export const LOG_ROCKET_COOKIE_NAME = 'logrocket';
export const LOG_ROCKET_COOKIE_VALUE = 'logrocket started';
export const LOG_ROCKET_LAUNCH_ID = 'lgrckt';

//banners
export const BANNERS_DATA = 'banners_data_updated';
export const APP_BANNER_COOKIE_NAME = 'appBanerShow';

export const USER_CABINET_URL =
  process.env.REACT_APP_USER_CABINET_URL || 'https://my.briz.ua/brizTV';

export const WEBSOCKET_STREAM_URL = process.env.REACT_APP_WEBSOCKET_STREAM_URL;
